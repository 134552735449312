html,
body,
#__next,
#__next > div {
  height: 100%;
}

body {
  overflow-x: hidden;
}

@media screen and (max-width: 600px) {
  .hr-desktop {
    display: none;
  }
}
